<template>
    <div>
        <div class="erorr-width">
            <div class="erorr-width__image">
                <img src="/images/homeIcon.png" alt="erorr-width" />
            </div>
            <div class="erorr-width__text">
                <h1 class="erorr-width__title">Oops! Something went wrong</h1>
                <p class="erorr-width__description">Your screen is smaller than 24 inches. Please use a larger screen.</p>
            </div>
        </div>
    </div>
</template>
<script setup>
</script>
<style lang="scss">
.erorr-width{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    &__text{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        p{
            margin: 0;
            font-size: 20px;
        }
    }
}
</style>
