<template>
  <div class="default-layout__wrapper">
    <div class="default-layout">
      <header-title/>
      <div class="default-layout__view--content">
        <div v-if="isSmallScreen">
          <error-width/>
        </div>
        <slot v-else />
      </div>
    </div>
  </div>
</template>

<script>
export default {
    middleware: ["auth"],
    data() {
        return {
            isSmallScreen: false
        };
    },
    mounted() {
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
    },
    methods: {
        checkScreenSize() {
            const screenWidth = window.innerWidth;
            // this.isSmallScreen = screenWidth < 1600;
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
   
}
</script>

<style lang="scss">
.default-layout {
 // display: flex;
  min-height: 100vh;
  background: var(--neutral-n-2);
  position: relative;

  width: 100%;

  &__wrapper {
 //   display: flex;
   // flex: 1;
    overflow: hidden;
    background: var(--neutral-n-2);
  }

  &__view {
    flex: 1;
    overflow: auto;
    // padding: 28px 32px 29px 24px;
    display: flex;
    flex-direction: column;
    gap: 23px;

    &--content {
      min-width: 1000px;

      @media (max-width: 1600px) {
        min-width: 100%;
      }
    }
  }

  .ant-layout {
    background: transparent;
  }

  .ant-layout-sider {
    //overflow-y: auto;
    //    height: 100vh;
    background: var(--Red-Primary, #0D52A2);
  }
}
</style>