<template>
    <div class="header-title">
        <div class="title">
            <img src="/images/logo.png" alt="logo" />
            <span>LSI Scheduler</span>
        </div>

        <div class="actions">
            <nuxt-link :to="isAdminPath ? '/' : '/admin'" v-if="authUser?.role === 'admin'">
                Go to {{ isAdminPath ? 'LSI Scheduler' : 'Admin' }}
            </nuxt-link>
            <nuxt-link to="https://lifescangroup.zendesk.com/agent" target="_blank">
                Login to Zendesk
            </nuxt-link>
            <a-dropdown :trigger="['click']">
                <div class="account-info">
                    <div class="logo">
                        <img :src="authUser.picture" alt="avatar" v-if="authUser?.picture" />
                        <span v-else>{{ $filters.avatarText(renderName) }}</span>
                    </div>
                    <div class="name">
                        <span>{{ renderName }}</span>
                    </div>
                    <DownOutlined style="margin-left: 10px; font-size: 12px;" />
                </div>
                <template #overlay>
                    <a-menu>
                        <a-menu-divider />
                        <a-menu-item key="logout" @click="handleLogout">
                            <LogoutOutlined />
                            Logout
                        </a-menu-item>
                    </a-menu>
                </template>
            </a-dropdown>
        </div>

    </div>
</template>
<script setup>
import { useAuthStore } from '~/stores/useAuthStore'
import { useAuth0 } from '~/services/auth0.service';

const router = useRouter()

const authStore = useAuthStore()
const { logout } = authStore
const authUser = computed(() => {
    const { role } = authStore?.authUser || { role: '' }
    if (role !== 'admin' && router.currentRoute.value.path.includes('/admin')) {
        navigateTo('/');
    }
    return authStore.authUser
})
const renderName = computed(() => {
    return authUser.value?.name || authUser.value?.email || 'Admin'
})

const isAdminPath = computed(() => {
    return router.currentRoute.value.path.includes('/admin')
})

const handleLogout = async () => {
   try {
    const auth0Client = await useAuth0()
    await auth0Client.logout({
        logoutParams: {
            returnTo: window.location.origin
        }
    });
   } catch (error) {
        console.log(error);
   }
}
</script>
<style lang="scss">
.header-title {
    background: #EDE5DA;
    height: 64px;
    margin-bottom: 4px;
    padding: 0 20px;
    width: calc(100% - 0px);

    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;

        img {
            width: 28px;
            height: 28px;
            margin-right: 10px;
        }

        span {
            font-size: 20px;
            font-weight: 600;

            @media (max-width: 768px) {
                font-size: 16px;
            }
        }
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        gap: 20px;

        a {
            color: #000;
            font-size: 16px;
            font-weight: 600;
            text-decoration: none;

            padding: 10px 20px;
            border-radius: 4px;
            background: #fff;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.28);
        }
    }

    .account-info {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        cursor: pointer;

        .logo {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
            background: var(--primary-p);
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            span {
                color: var(--neutral-n-1);
                text-align: center;
                font-family: Urbanist;
                font-size: 12px;
                font-style: normal;
            }
        }

        .name {
            @media (max-width: 768px) {
                display: none;
            }
            span {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
}
</style>